<template>
  <div class="container mypage withTop scrollWrap">
    <section class="contents Rgt" ref="Rgt">
      <p class="bold">농가현황</p>
      <article class="box">
        <p>농장 명칭 <span>*</span></p>
        <input type="text" @click="handleFocus" v-model="farmName" />
        <p>농장 주소 <span>*</span></p>
        <div class="addressWrap">
          <div>
            <input type="text" v-model="address" />
            <button v-show="address" @click="addressReset"><img src="@/assets/images/highlight_off.png" /></button>
          </div>
          <button @click="searchAddressToCoordinate(address)">검색</button>
        </div>
        <div class="map" id="map" v-show="mapVisible"></div>

        <el-drawer title="주소 선택" :visible.sync="drawer" direction="btt">
          <ul>
            <li v-for="(data, i) in addressList" :key="i" @click="addressCheck(data)">
              <p>
                <span>도로명</span><span>{{ data.roadAddress }}</span>
              </p>
              <p>
                <span>지번</span><span>{{ data.jibunAddress }}</span>
              </p>
            </li>
          </ul>
        </el-drawer>

        <p>재배품목 <span>*</span></p>
        <el-select v-model="crops">
          <el-option value="딸기" label="딸기"></el-option>
          <el-option value="토마토" label="토마토"></el-option>
          <el-option value="사과" label="사과"></el-option>
          <el-option value="고추" label="고추"></el-option>
          <el-option value="포도" label="포도"></el-option>
          <el-option value="레드향" label="레드향"></el-option>
          <el-option value="상추" label="상추"></el-option>
        </el-select>
        <p>농장주 연령</p>
        <Datepicker v-model="ages" :language="ko" :format="customFormatter" placeholder="선택" @focusin.native="handleFocus"></Datepicker>
        <p>농장주 경력</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="career" />
        <p>재배면적(㎡) <span>*</span></p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="area" />
        <p>수확일자 <span>*</span></p>
        <Datepicker v-model="hvstDay" :language="ko" :format="customFormatter" placeholder="선택" @focusin.native="handleFocus"></Datepicker>
      </article>

      <p class="bold">환경제어 프로그램 현황</p>
      <article class="box">
        <p>PC 운영체계</p>
        <input type="text" v-model="os" @click="handleFocus" />
        <p>모니터(인치)</p>
        <input type="number" pattern="\d*" v-model="monitorSize" @click="handleFocus" />
        <p>환경제어 프로그램명</p>
        <input type="text" v-model="envrnCtrlProgram" @click="handleFocus" />
        <div class="flexB">
          <p>UPS 설치여부</p>
          <el-switch v-model="ups"> </el-switch>
        </div>
        <p>PC 설치위치</p>
        <input type="text" v-model="pcLocation" @click="handleFocus" />
        <div class="flexB">
          <p>인터넷 설치여부</p>
          <el-switch v-model="internet"> </el-switch>
        </div>
      </article>

      <p class="bold">시설현황</p>
      <article class="box">
        <p>하우스 유형</p>
        <el-checkbox-group v-model="houseType">
          <el-checkbox label="단동"></el-checkbox>
          <el-checkbox label="연동"></el-checkbox>
        </el-checkbox-group>
        <p>하우스 설치방향</p>
        <el-radio v-model="houseDirection" label="동서">동서</el-radio>
        <el-radio v-model="houseDirection" label="남북">남북</el-radio>
        <p>하우스 피복필름 종류</p>
        <el-radio v-model="houseCover" label="PO">PO</el-radio>
        <el-radio v-model="houseCover" label="PE">PE</el-radio>
        <p>하우스 파이프 길이(m)</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="housePipeLength" />
        <p>하우스 높이(m)</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="houseHeight" />
        <p>하우스 폭(m)</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="houseWidth" />
        <p>하우스 길이(m)</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="houseLength" />
        <p>보온자재 종류</p>
        <input type="text" @click="handleFocus" v-model="houseInsulation" />
        <p>난방방법</p>
        <el-radio v-model="houseHeating" label="열풍">열풍</el-radio>
        <el-radio v-model="houseHeating" label="수막">수막</el-radio>
        <p>환기방법</p>
        <el-checkbox-group v-model="houseVentilation">
          <el-checkbox label="출입구"></el-checkbox>
          <el-checkbox label="측창"></el-checkbox>
          <el-checkbox label="천창"></el-checkbox>
          <el-checkbox label="고정팬"></el-checkbox>
          <el-checkbox label="유동팬"></el-checkbox>
        </el-checkbox-group>
        <p>관수방법</p>
        <el-radio v-model="houseIrrigation" label="점적">점적</el-radio>
        <el-radio v-model="houseIrrigation" label="스프링클러">스프링클러</el-radio>
      </article>

      <p class="bold">외부측정장치 설치현황</p>
      <article class="box">
        <div class="flexB">
          <p>온도센서</p>
          <el-switch v-model="outsideTempSensor"> </el-switch>
        </div>
        <div class="flexB">
          <p>풍향센서</p>
          <el-switch v-model="windDirectionSensor"> </el-switch>
        </div>
        <div class="flexB">
          <p>풍속센서</p>
          <el-switch v-model="windSpeedSensor"> </el-switch>
        </div>
        <div class="flexB">
          <p>일사량센서</p>
          <el-switch v-model="insolationSensor"> </el-switch>
        </div>
        <div class="flexB">
          <p>감우센서</p>
          <el-switch v-model="rainfallSensor"> </el-switch>
        </div>
      </article>

      <p class="bold">내부측정장치 설치현황</p>
      <article class="box">
        <div class="flexB">
          <p>온도센서</p>
          <el-switch v-model="insideTempSensor"> </el-switch>
        </div>
        <div class="flexB">
          <p>습도센서</p>
          <el-switch v-model="humiditySensor"> </el-switch>
        </div>
        <div class="flexB">
          <p>CO2센서</p>
          <el-switch v-model="co2Sensor"> </el-switch>
        </div>
        <div class="flexB">
          <p>광량센서</p>
          <el-switch v-model="lightSensor"> </el-switch>
        </div>
        <div class="flexB">
          <p>지온센서</p>
          <el-switch v-model="soilTempSensor"> </el-switch>
        </div>
      </article>

      <p class="bold">제어</p>
      <article class="box">
        <div class="flexB">
          <p>일중천장</p>
          <el-switch v-model="ceilingWndw"> </el-switch>
        </div>
        <div class="flexB">
          <p>이중천장</p>
          <el-switch v-model="dplcWndw"> </el-switch>
        </div>
        <div class="flexB">
          <p>측창</p>
          <el-switch v-model="sideWallWndw"> </el-switch>
        </div>
        <div class="flexB">
          <p>천장보온커튼</p>
          <el-switch v-model="cellarsHeatCurtain"> </el-switch>
        </div>
        <div class="flexB">
          <p>측면보온커튼</p>
          <el-switch v-model="wallHeatCurtain"> </el-switch>
        </div>
        <div class="flexB">
          <p>차광커튼</p>
          <el-switch v-model="shadeCurtain"> </el-switch>
        </div>
        <div class="flexB">
          <p>난방기</p>
          <el-switch v-model="heatMhrls"> </el-switch>
        </div>
        <div class="flexB">
          <p>CO2공급</p>
          <el-switch v-model="co2Supply"> </el-switch>
        </div>
        <div class="flexB">
          <p>광량제어</p>
          <el-switch v-model="lightControl"> </el-switch>
        </div>
        <div class="flexB">
          <p>유동팬</p>
          <el-switch v-model="dynmcFan"> </el-switch>
        </div>
        <div class="flexB">
          <p>관수/관비 시스템</p>
          <el-switch v-model="irrnFrtlzrSys"> </el-switch>
        </div>
        <div class="flexB">
          <p>양액기</p>
          <el-switch v-model="nutrientSlutnSys"> </el-switch>
        </div>
        <div class="flexB">
          <p>두상살수장치</p>
          <el-switch v-model="sprinkler"> </el-switch>
        </div>
        <div class="flexB">
          <p>훈증기</p>
          <el-switch v-model="fumigator"> </el-switch>
        </div>
      </article>

      <p class="bold">재배기초</p>
      <article class="box">
        <p>재배 유형</p>
        <el-checkbox-group v-model="ctvtTY">
          <el-checkbox label="양액재배"></el-checkbox>
          <el-checkbox label="토경재배"></el-checkbox>
        </el-checkbox-group>
        <p>품종명</p>
        <input type="text" @click="handleFocus" v-model="spcies" />
        <p>종묘확보방법</p>

        <el-radio v-model="seedling" label="자가">자가</el-radio>
        <el-radio v-model="seedling" label="구입">구입</el-radio>

        <p>배지종류</p>
        <input type="text" @click="handleFocus" v-model="soilType" />
        <p>자가퇴비여부</p>
        <el-radio v-model="compost" label="자가">자가</el-radio>
        <el-radio v-model="compost" label="구입">구입</el-radio>

        <p>정식일</p>
        <Datepicker v-model="ptngDt" :language="ko" :format="customFormatter" placeholder="선택" @focusin.native="handleFocus"></Datepicker>
        <p>수확시작일</p>
        <Datepicker v-model="hvstBgnde" :language="ko" :format="customFormatter" placeholder="선택" @focusin.native="handleFocus"></Datepicker>
        <p>수확종료일</p>
        <Datepicker v-model="hvstEndde" :language="ko" :format="customFormatter" placeholder="선택" @focusin.native="handleFocus"></Datepicker>

        <p>병해충 방제회수</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="dbyhsCccrrncDgree" />
        <p>병해충 발생정도</p>
        <input type="text" @click="handleFocus" v-model="dbyhsPrvnbeRtrvl" />
      </article>

      <p class="bold">토양(근권)환경</p>
      <article class="box">
        <p>공급양액 PH농도</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="nutrientPH" />
        <p>공급양액 EC</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="nutrientEC" />
        <p>배지PH</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="mdimPH" />
        <p>배지 EC</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="mdimEC" />
      </article>

      <p class="bold">데이터 수집현황</p>
      <article class="box">
        <div class="flexB">
          <p>환경</p>
          <el-switch v-model="environment"> </el-switch>
        </div>
        <div class="flexB">
          <p>관수(양액)</p>
          <el-switch v-model="irrigation"> </el-switch>
        </div>
        <div class="flexB">
          <p>생육</p>
          <el-switch v-model="growh"> </el-switch>
        </div>
        <p>생산량</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="output" />
      </article>

      <p class="bold">영농비용</p>
      <article class="box">
        <p>농약비</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="pesticideCost" />
        <p>광열동력비</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="photothermalCost" />
        <p>기타(컨설팅 등)</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="etcCost" />
        <p>위탁영농비</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="consignmentCost" />
        <p>고용노력비</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="employCost" />
        <p>경영비</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="managementCost" />
        <p>소득</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="income" />
      </article>

      <button class="point full" @click="submit">
        {{ editMode == true ? "수정하기" : "등록하기" }}
      </button>
    </section>
  </div>
</template>
<script type="text/javascript" src="https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=4b2clfhf47&submodules=geocoder"></script>
<script>
import { createModel } from "@/api/growth";
import { createFarm, fetchFarmDetails, updateFarm } from "@/api/farm";
import { mapState } from "vuex";
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
export default {
  components: { Datepicker },

  data() {
    return {
      ko: ko,
      moment: moment,
      farmId: "",
      editMode: false,

      farmName: "",
      mainFarm: false,
      address: "",
      ages: "",
      career: "",
      area: "",
      crops: "",

      os: "",
      monitorSize: "",
      envrnCtrlProgram: "",
      pcLocation: "",

      houseType: [],
      houseDirection: "",
      houseCover: "",
      houseHeating: "",
      houseIrrigation: "",
      housePipeLength: "",
      houseHeight: "",
      houseWidth: "",
      houseLength: "",
      houseInsulation: "",
      houseVentilation: [],
      ups: false,
      internet: false,
      outsideTempSensor: false,
      windDirectionSensor: false,
      windSpeedSensor: false,
      insolationSensor: false,
      rainfallSensor: false,
      insideTempSensor: false,
      humiditySensor: false,
      co2Sensor: false,
      lightSensor: false,
      soilTempSensor: false,
      ceilingWndw: false,
      dplcWndw: false,
      sideWallWndw: false,
      cellarsHeatCurtain: false,
      wallHeatCurtain: false,
      shadeCurtain: false,
      heatMhrls: false,
      co2Supply: false,
      lightControl: false,
      dynmcFan: false,
      irrnFrtlzrSys: false,
      nutrientSlutnSys: false,
      sprinkler: false,
      fumigator: false,
      ctvtTY: [],
      spcies: "",
      seedling: "",
      soilType: "",
      compost: "",
      ptngDt: "",
      hvstBgnde: "",
      hvstEndde: "",
      dbyhsCccrrncDgree: "",
      dbyhsPrvnbeRtrvl: "",
      nutrientPH: "",
      nutrientEC: "",
      mdimPH: "",
      mdimEC: "",

      environment: false,
      irrigation: false,
      growh: false,
      output: "",
      hvstDay: "",

      pesticideCost: "",
      photothermalCost: "",
      etcCost: "",
      consignmentCost: "",
      employCost: "",
      managementCost: "",
      income: "",

      nx: "",
      ny: "",

      mapVisible: false,
      map: null,
      marker: null,
      mapOptions: {
        center: new naver.maps.LatLng(0, 0),
        zoom: 16, // 10~21 사이 숫자
        mapTypeControl: false,
      },

      drawer: false,
      addressList: [],
    };
  },
  computed: {
    ...mapState(["userId", "userType"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 4);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "농장등록");
  },
  mounted() {
    if (this.$route.query.id) {
      this.farmId = this.$route.query.id;
      this.editMode = true;
      this.getFarmDetail();
    }
    this.setMap();
  },
  methods: {
    addressReset() {
      this.address = "";
      this.mapVisible = false;
    },
    setMap() {
      this.map = new naver.maps.Map("map", this.mapOptions);
      this.marker = new naver.maps.Marker({
        position: new naver.maps.LatLng(0, 0),
        map: this.map,
      });
    },
    searchAddressToCoordinate(address) {
      if (address == "") {
        this.openAlert("주소를 입력해 주세요.");
        return;
      }
      naver.maps.Service.geocode(
        {
          query: address,
        },
        (status, response) => {
          if (status === naver.maps.Service.Status.ERROR) {
            this.openAlert("주소를 다시 입력해 주세요.");
            return;
          } else if (response.v2.meta.totalCount === 0) {
            this.openAlert("주소를 다시 입력해 주세요.");
            return;
          } else {
            if (response.v2.status == "OK") {
              let addresses = response.v2.addresses;
              if (addresses.length > 1) {
                this.addressList = addresses;
                this.drawer = true;
              } else {
                let lng = addresses[0].x;
                let lat = addresses[0].y;
                this.address = addresses[0].jibunAddress;
                this.map.setCenter(naver.maps.LatLng(lat, lng));
                this.marker.setPosition(naver.maps.LatLng(lat, lng));
                this.mapVisible = true;
                this.handleGridXY("toXY", lat, lng);
              }
            }
          }
        }
      );
    },
    addressCheck(data) {
      let lng = data.x;
      let lat = data.y;
      this.address = data.jibunAddress;
      this.map.setCenter(naver.maps.LatLng(lat, lng));
      this.marker.setPosition(naver.maps.LatLng(lat, lng));
      this.handleGridXY("toXY", lat, lng);
      this.mapVisible = true;
      this.drawer = false;
    },

    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    handleFocus() {
      this.$refs.Rgt.style.paddingBottom = "200px";
      document.activeElement.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    execDaumPostcode() {
      var map = new naver.maps.Map("map", {
        center: new naver.maps.LatLng(37.3595316, 127.1052133),
        zoom: 15,
        mapTypeControl: true,
      });
    },

    handleGridXY(code, v1, v2) {
      var RE = 6371.00877; // 지구 반경(km)
      var GRID = 5.0; // 격자 간격(km)
      var SLAT1 = 30.0; // 투영 위도1(degree)
      var SLAT2 = 60.0; // 투영 위도2(degree)
      var OLON = 126.0; // 기준점 경도(degree)
      var OLAT = 38.0; // 기준점 위도(degree)
      var XO = 43; // 기준점 X좌표(GRID)
      var YO = 136; // 기1준점 Y좌표(GRID)

      var DEGRAD = Math.PI / 180.0;
      var RADDEG = 180.0 / Math.PI;

      var re = RE / GRID;
      var slat1 = SLAT1 * DEGRAD;
      var slat2 = SLAT2 * DEGRAD;
      var olon = OLON * DEGRAD;
      var olat = OLAT * DEGRAD;

      var sn = Math.tan(Math.PI * 0.25 + slat2 * 0.5) / Math.tan(Math.PI * 0.25 + slat1 * 0.5);
      sn = Math.log(Math.cos(slat1) / Math.cos(slat2)) / Math.log(sn);
      var sf = Math.tan(Math.PI * 0.25 + slat1 * 0.5);
      sf = (Math.pow(sf, sn) * Math.cos(slat1)) / sn;
      var ro = Math.tan(Math.PI * 0.25 + olat * 0.5);
      ro = (re * sf) / Math.pow(ro, sn);
      var rs = {};
      if (code == "toXY") {
        rs["lat"] = v1;
        rs["lng"] = v2;
        var ra = Math.tan(Math.PI * 0.25 + v1 * DEGRAD * 0.5);
        ra = (re * sf) / Math.pow(ra, sn);
        var theta = v2 * DEGRAD - olon;
        if (theta > Math.PI) theta -= 2.0 * Math.PI;
        if (theta < -Math.PI) theta += 2.0 * Math.PI;
        theta *= sn;
        rs["x"] = Math.floor(ra * Math.sin(theta) + XO + 0.5);
        rs["y"] = Math.floor(ro - ra * Math.cos(theta) + YO + 0.5);

        this.nx = rs.x;
        this.ny = rs.y;
      } else {
        rs["x"] = v1;
        rs["y"] = v2;
        var xn = v1 - XO;
        var yn = ro - v2 + YO;
        ra = Math.sqrt(xn * xn + yn * yn);
        if (sn < 0.0) -ra;
        var alat = Math.pow((re * sf) / ra, 1.0 / sn);
        alat = 2.0 * Math.atan(alat) - Math.PI * 0.5;

        if (Math.abs(xn) <= 0.0) {
          theta = 0.0;
        } else {
          if (Math.abs(yn) <= 0.0) {
            theta = Math.PI * 0.5;
            if (xn < 0.0) -theta;
          } else theta = Math.atan2(xn, yn);
        }
        var alon = theta / sn + olon;
        rs["lat"] = alat * RADDEG;
        rs["lng"] = alon * RADDEG;
      }
    },
    initMap() {},
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    getFarmDetail() {
      fetchFarmDetails(this.farmId).then((res) => {
        if (res.data.status == 200) {
          this.farmer = res.data.data.farmer;
          this.farmName = res.data.data.farmName;
          this.address = res.data.data.address;
          this.ages = res.data.data.ages;
          this.career = res.data.data.career;
          this.area = res.data.data.area;
          this.crops = res.data.data.crops;
          this.os = res.data.data.os;
          this.monitorSize = res.data.data.monitorSize;
          this.envrnCtrlProgram = res.data.data.envrnCtrlProgram;
          this.pcLocation = res.data.data.pcLocation;
          this.houseType = res.data.data.houseType;
          this.houseDirection = res.data.data.houseDirection;
          this.houseCover = res.data.data.houseCover;
          this.houseHeating = res.data.data.houseHeating;
          this.houseIrrigation = res.data.data.houseIrrigation;
          this.housePipeLength = res.data.data.housePipeLength;
          this.houseHeight = res.data.data.houseHeight;
          this.houseWidth = res.data.data.houseWidth;
          this.houseLength = res.data.data.houseLength;
          this.houseInsulation = res.data.data.houseInsulation;
          this.houseVentilation = res.data.data.houseVentilation;
          this.mainFarm = res.data.data.mainFarm;
          this.ups = res.data.data.ups;
          this.internet = res.data.data.internet;
          this.outsideTempSensor = res.data.data.outsideTempSensor;
          this.windDirectionSensor = res.data.data.windDirectionSensor;
          this.windSpeedSensor = res.data.data.windSpeedSensor;
          this.insolationSensor = res.data.data.insolationSensor;
          this.rainfallSensor = res.data.data.rainfallSensor;
          this.insideTempSensor = res.data.data.insideTempSensor;
          this.humiditySensor = res.data.data.humiditySensor;
          this.co2Sensor = res.data.data.co2Sensor;
          this.lightSensor = res.data.data.lightSensor;
          this.soilTempSensor = res.data.data.soilTempSensor;
          this.ceilingWndw = res.data.data.ceilingWndw;
          this.dplcWndw = res.data.data.dplcWndw;
          this.sideWallWndw = res.data.data.sideWallWndw;
          this.cellarsHeatCurtain = res.data.data.cellarsHeatCurtain;
          this.wallHeatCurtain = res.data.data.wallHeatCurtain;
          this.shadeCurtain = res.data.data.shadeCurtain;
          this.heatMhrls = res.data.data.heatMhrls;
          this.co2Supply = res.data.data.co2Supply;
          this.lightControl = res.data.data.lightControl;
          this.dynmcFan = res.data.data.dynmcFan;
          this.irrnFrtlzrSys = res.data.data.irrnFrtlzrSys;
          this.nutrientSlutnSys = res.data.data.nutrientSlutnSys;
          this.sprinkler = res.data.data.sprinkler;
          this.fumigator = res.data.data.fumigator;
          this.ctvtTY = res.data.data.ctvtTY;
          this.spcies = res.data.data.spcies;
          this.seedling = res.data.data.seedling;
          this.soilType = res.data.data.soilType;
          this.compost = res.data.data.compost;
          this.ptngDt = res.data.data.ptngDt;
          this.hvstBgnde = res.data.data.hvstBgnde;
          this.hvstEndde = res.data.data.hvstEndde;
          this.dbyhsCccrrncDgree = res.data.data.dbyhsCccrrncDgree;
          this.dbyhsPrvnbeRtrvl = res.data.data.dbyhsPrvnbeRtrvl;
          this.nutrientPH = res.data.data.nutrientPH;
          this.nutrientEC = res.data.data.nutrientEC;
          this.mdimPH = res.data.data.mdimPH;
          this.mdimEC = res.data.data.mdimEC;
          this.environment = res.data.data.environment;
          this.irrigation = res.data.data.irrigation;
          this.growh = res.data.data.growh;
          this.output = res.data.data.output;
          this.hvstDay = res.data.data.hvstDay;
          this.pesticideCost = res.data.data.pesticideCost;
          this.photothermalCost = res.data.data.photothermalCost;
          this.etcCost = res.data.data.etcCost;
          this.consignmentCost = res.data.data.consignmentCost;
          this.employCost = res.data.data.employCost;
          this.managementCost = res.data.data.managementCost;
          this.income = res.data.data.income;
          this.createdAt = res.data.data.createdAt;
          this.nx = res.data.data.nx;
          this.ny = res.data.data.ny;
        }
      });
    },
    submit() {
      if (this.farmName == "") {
        this.openAlert("농장 명칭을 입력해 주세요.");
        return;
      } else if (this.address == "") {
        this.openAlert("주소를 입력해 주세요.");
        return;
      } else if (this.editMode == false && this.mapVisible == false) {
        this.openAlert("주소 검색버튼을 눌러서 위치를 확인해 주세요.");
        return;
      } else if (this.crops == "") {
        this.openAlert("재배품목을 선택해 주세요.");
        return;
      } else if (this.area == "") {
        this.openAlert("재배면적을 입력해 주세요.");
        return;
      } else if (this.hvstDay == null) {
        this.openAlert("수확일자를 선택해 주세요.");
        return;
      }
      let data = {
        farmId: this.farmId,
        farmName: this.farmName,
        mainFarm: this.mainFarm,
        address: this.address,
        ages: this.ages,
        career: this.career,
        area: this.area,
        crops: this.crops,
        os: this.os,
        monitorSize: this.monitorSize,
        envrnCtrlProgram: this.envrnCtrlProgram,
        pcLocation: this.pcLocation,
        houseType: this.houseType,
        houseDirection: this.houseDirection,
        houseCover: this.houseCover,
        houseHeating: this.houseHeating,
        houseIrrigation: this.houseIrrigation,
        housePipeLength: this.housePipeLength,
        houseHeight: this.houseHeight,
        houseWidth: this.houseWidth,
        houseLength: this.houseLength,
        houseInsulation: this.houseInsulation,
        houseVentilation: this.houseVentilation,
        ups: this.ups,
        internet: this.internet,
        outsideTempSensor: this.outsideTempSensor,
        windDirectionSensor: this.windDirectionSensor,
        windSpeedSensor: this.windSpeedSensor,
        insolationSensor: this.insolationSensor,
        rainfallSensor: this.rainfallSensor,
        insideTempSensor: this.insideTempSensor,
        humiditySensor: this.humiditySensor,
        co2Sensor: this.co2Sensor,
        lightSensor: this.lightSensor,
        soilTempSensor: this.soilTempSensor,
        ceilingWndw: this.ceilingWndw,
        dplcWndw: this.dplcWndw,
        sideWallWndw: this.sideWallWndw,
        cellarsHeatCurtain: this.cellarsHeatCurtain,
        wallHeatCurtain: this.wallHeatCurtain,
        shadeCurtain: this.shadeCurtain,
        heatMhrls: this.heatMhrls,
        co2Supply: this.co2Supply,
        lightControl: this.lightControl,
        dynmcFan: this.dynmcFan,
        irrnFrtlzrSys: this.irrnFrtlzrSys,
        nutrientSlutnSys: this.nutrientSlutnSys,
        sprinkler: this.sprinkler,
        fumigator: this.fumigator,
        ctvtTY: this.ctvtTY,
        spcies: this.spcies,
        seedling: this.seedling,
        soilType: this.soilType,
        compost: this.compost,
        ptngDt: this.ptngDt,
        hvstBgnde: this.hvstBgnde,
        hvstEndde: this.hvstEndde,
        dbyhsCccrrncDgree: this.dbyhsCccrrncDgree,
        dbyhsPrvnbeRtrvl: this.dbyhsPrvnbeRtrvl,
        nutrientPH: this.nutrientPH,
        nutrientEC: this.nutrientEC,
        mdimPH: this.mdimPH,
        mdimEC: this.mdimEC,
        environment: this.environment,
        irrigation: this.irrigation,
        growh: this.growh,
        output: this.output,
        hvstDay: this.hvstDay,
        pesticideCost: this.pesticideCost,
        photothermalCost: this.photothermalCost,
        etcCost: this.etcCost,
        consignmentCost: this.consignmentCost,
        employCost: this.employCost,
        managementCost: this.managementCost,
        income: this.income,
        nx: this.nx,
        ny: this.ny,
      };

      if (this.editMode == true) {
        updateFarm(data).then(async (res) => {
          if (res.data.status == 200) {
            let farm = {
              farmId: this.farmId,
            };
            await createModel(farm).then((res) => {});

            if (this.userType == "EXAMINER") {
              this.$store.dispatch("getFarmerList");
            } else {
              this.$store.dispatch("getUserFarmList");
            }
            this.$router.replace("/mypage/farmList");
          } else {
            this.openAlert(res.data.message);
          }
        });
      } else {
        createFarm(data).then((res) => {
          if (res.data.status == 200) {
            if (this.userType == "EXAMINER") {
              this.$store.dispatch("getFarmerList");
            } else {
              this.$store.dispatch("getUserFarmList");
            }
            this.$router.replace("/mypage/farmList");
          } else {
            this.openAlert(res.data.message);
          }
        });
      }
    },
  },
};
</script>
